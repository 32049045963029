export default {
  address: "台北市大安區杭州南路二段61巷38號",
  googleSrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.122683596384!2d121.52230751500623!3d25.029910283974253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a99b5c9008c9%3A0xd609543f48213b6!2zMTA25Y-w5YyX5biC5aSn5a6J5Y2A5p2t5bee5Y2X6Lev5LqM5q61NjHlt7czOOiZnw!5e0!3m2!1szh-TW!2stw!4v1623742907251!5m2!1szh-TW!2stw",
  googleLink: "https://goo.gl/maps/oiHRsxA7gAUaz6ec8",
  phone: "02-23913131",
  fbLink:
    "https://www.facebook.com/%E8%99%B9%E8%80%80%E4%BB%8A%E9%87%87-100616525574893",
  fbMessage: "https://m.me/100616525574893/",
  caseName: "虹耀今采",
  indigatorLength: 10,
  houseInfos: [
    ["投資興建", "虹耀建設股份有限公司"],
    ["園藝設計", "六國景觀設計"],
    ["建築營造", "森城營造"],
    ["公設規劃", "元佑設計"],
    ["建築規劃", "林峰池建築師事務所"],
    ["建案位置", "台北市大安區杭州南路二段61巷38號"],
    ["企劃銷售", "<a style='color:#000'  href='https://www.haiwo.com.tw/' target='_blank'>海沃創意行銷股份有限公司</a>"]
  ],

  gtmCode: ["W4WJZN4"], // 可放置多個
  recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
  recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
  recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
  order: {
    title: "預約賞屋",
    subTitle: ""
  }
};
